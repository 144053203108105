<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="派发救援工单"
                :visible.sync="visible"
                width="600px"
            >
                <el-form 
                    ref="distributeForm"
                    label-width="100px" 
                    class="distribute-fault-form"
                    :model="formData"
                    :rules="rules"
                >
                    <el-form-item label="故障类型" :label-width="'150px'">
                        <el-select placeholder="故障类型" :style="{width: '315px'}" v-model="formData.faultLevel" disabled>
                            <el-option label="单体温度过低" :value="1"></el-option>
                            <el-option label="单体温度过高" :value="2"></el-option>
                            <el-option label="单体电压过低" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运维人员" :label-width="'150px'" prop="userId">
                        <el-select placeholder="运维人员" :style="{width: '315px'}" v-model="formData.userId">
                            <el-option v-for="item in operationPersonList" :key="item.id" :label="item.userName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label-width="'150px'" label="上传模板" prop="fileUrl">
                            <el-upload
                                action="/api/file/upload"
                                multiple
                                :limit="1"
                                :headers="{ token: getToken() }"
                                :file-list="fileList"
                                :on-success="onSuccessHandel"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios'
import './DistributeModal.less';
export default {
    name: 'DistributeModal',
    props: {
        jobCode: {
            type: String,
            default: '',
        },
        faultLevel: {
            type: Number,
            default: -1
        },
        getData: {
            type: Function,
            default: () => {}
        } 
    },
    data() {
        return {
            visible: false,
            formData: {
                userId: '',
                faultLevel: -1,
                fileUrl: ''
            },
            rules: {    
                userId: [
                    { required: true, message: '请选择运维人员', trigger: 'blur' },
                ]
            },
            operationPersonList: [],
            fileList: []
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            if (flag) {
                this.formData.faultLevel = this.faultLevel;
                this.getOperateUserList();
            }
        },
        submitHandel() {
            this.$refs.distributeForm.validate(valid => {
                if (valid) {
                    axios.post('/api/job/allocationJob', { 
                        jobCode: this.jobCode,
                        userId: this.formData.userId,
                        fileUrl: this.formData.fileUrl
                    }).then(() => {
                        this.$message.success('派单成功');
                        this.visible = false;
                        this.fileList = [];
                        this.getData();
                    }).catch(err => {
                        this.$message.error(err);
                    });
                }
            });
        },
        getOperateUserList() {
            axios.get(`/api/operationPerson/pageList/${1}/${100}`, {}).then(rs => {
                this.operationPersonList = rs.records;
            });
        },
        onSuccessHandel(response) {
            this.formData.fileUrl = response.data.path;
        },
        getToken() {
			return localStorage.getItem('user-token');
		},
    }
}
</script>