var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fault-rescue-management"},[_c('div',{staticClass:"table-tool"},[_c('TableTool',{ref:"tableTool",attrs:{"page":_vm.page,"pageSize":_vm.pageSize},on:{"dataChange":_vm.dataChangeHandel}})],1),_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"height":"100%","data":_vm.list}},[_c('el-table-column',{attrs:{"label":"工单状态","prop":"jobStatusLable","width":"100"}}),_c('el-table-column',{attrs:{"label":"时间","prop":"jobTimes","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.jobTimes.jobCreateTime)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"期望接单时长","prop":"limitMinTime","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.jobStatus === 20 ? ((row.limitMinTime) + "分钟") : '-')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"超时倒计时","prop":"timeoutDown","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.jobStatus !== 20)?_c('span',[_vm._v("-")]):_vm._e(),(row.jobStatus === 20 && row.timeoutDown >= 0)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.formatSecond(row.timeoutDown))+" ")]):_vm._e(),(row.jobStatus === 20 && row.timeoutDown < 0)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" 超时"+_vm._s(_vm.formatSecond(-row.timeoutDown))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"故障类型","prop":"faultNames","width":"140"}}),_c('el-table-column',{attrs:{"label":"故障等级","prop":"faultLevel","width":"90"}}),_c('el-table-column',{attrs:{"label":"工单编号","prop":"jobCode","width":"210"}}),_c('el-table-column',{attrs:{"label":"电池类型","prop":"batteryType","width":"100"}}),_c('el-table-column',{attrs:{"label":"电池ID","prop":"batteryCode","width":"180"}}),_c('el-table-column',{attrs:{"label":"联系人","prop":"contacts","width":"100"}}),_c('el-table-column',{attrs:{"label":"联系电话","prop":"contactNumber","width":"130"}}),_c('el-table-column',{attrs:{"label":"电池位置","prop":"position","width":"180"}}),_c('el-table-column',{attrs:{"label":"操作","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"operate-a",on:{"click":function($event){return _vm.jumpDetailPage(row.jobCode)}}},[_vm._v("查看")]),(row.jobStatus === 10 || row.jobStatus === 20)?_c('a',{staticClass:"operate-a",on:{"click":function($event){return _vm.canceOrderHandel(row.jobCode)}}},[_vm._v("取消")]):_vm._e(),_c('DistributeModal',{attrs:{"faultLevel":Number(row.faultLevel),"jobCode":row.jobCode,"getData":_vm.getTableData}},[(row.jobStatus === 10)?_c('a',{staticClass:"operate-a"},[_vm._v("派发")]):_vm._e()])]}}])})],1)],1),_c('div',{staticClass:"table-page"},[_c('div',{staticClass:"table-page-container"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }