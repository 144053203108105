<template>
    <div class="table-tool-container">
        <div class="table-search-tool-container">
            <el-form class="battery-form" :inline="true" :model="formData">
                <el-form-item label="电池类型">
                    <el-select v-model="formData.batteryType" placeholder="电池类型" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="动力电池" :value=1></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电池ID">
                    <el-input v-model="formData.batteryCode" placeholder="电池ID" size="small"></el-input>
                </el-form-item>
                <el-form-item label="故障类型">
                    <el-select v-model="formData.faultNames" placeholder="故障类型" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option v-for="item in faultList" :key="item.key" :label="item.value" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="处理状态">
                    <el-select v-model="formData.jobStatus" placeholder="处理状态" size="small" class="area-form-item">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="待分配" :value="10"></el-option>
                        <el-option label="待接单" :value="20"></el-option>
                        <el-option label="处理中" :value="30"></el-option>
                        <el-option label="待审核" :value="40"></el-option>
                        <el-option label="已完成" :value="50"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                    <el-button @click="resetHandel">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-operate-tool-container">
            <CreateFaultModal :getData="getData">
                <el-button type="primary">新建</el-button>
            </CreateFaultModal>
            <el-button type="primary" style="marginLeft: 15px;" @click="exportTable">导出</el-button>
        </div>
    </div>
</template>

<script>
import CreateFaultModal from './CreateFaultModal';
import './TableTool.less';
import axios from '@/utils/axios';
import { downloadFile } from '@/utils/commonFun';
export default {
    name: 'TableTool',
    components: {
        CreateFaultModal
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            formData: {
                batteryType: '',
                batteryCode: '',
                faultNames: '',
                jobStatus: ''
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgId'
                }
            },
            faultList: []
        }
    },
    methods: {
        getData(_page) {
            const { page, pageSize, formData } = this;
            axios.get(`/api/rescueJob/pageList/${_page ? _page : page}/${pageSize}`, formData).then(
                rs => {
                    this.$emit('dataChange', rs);
                }
            ).catch(err => {
                this.$message.error(err);
            })
        },
        resetHandel() {
            this.formData = {
                batteryType: '',
                batteryCode: '',
                faultNames: '',
                jobStatus: ''
            };
        },
        getFaultList() {
            axios.get('/api/dict/list/alarmCode', {
                alarmCode: 'guzhang',
                alarmLevel: 'jibie'
            }).then(rs => {
                this.faultList = rs;
                const faultMap = {};
                rs.forEach(element => {
                    faultMap[element.key] = element.value;
                });
                this.faultMap = faultMap;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        exportTable() {
            const { formData } = this;
            const {
                batteryType,
                batteryCode,
                faultNames,
                jobStatus
            } = formData;
            let orgCode = '';
            const currentUserLocal = localStorage.getItem('currentUser');
            if (currentUserLocal) {
                orgCode = JSON.parse(currentUserLocal).org.orgCode;
            } 
            window.open(`/api/rescueJob/downExcel/1/100000?batteryType=${batteryType}&batteryCode=${batteryCode}&faultNames=${faultNames}&jobStatus=${jobStatus}&orgCode=${orgCode}`);
        },
    },
    mounted() {
        const { orderStatus } = this.$route.params;
        if (orderStatus) {
            this.formData.jobStatus = Number(orderStatus)
        }
        this.getData();
        this.getFaultList();
    }
}
</script>