<template>
    <div class="fault-rescue-management">
        <div class="table-tool">
            <TableTool ref="tableTool" :page="page" :pageSize="pageSize" @dataChange="dataChangeHandel"/>
        </div>
        <div class="table-container">
            <!-- <div class="table-data-info">
                <img class="tips-icon" :src="require('@/assets/img/volume.png')"/>
                <span>设备总量</span><span style="margin: 0 10px; color: #FF0000;">1</span>
                <span>本机构设备总量</span><span style="margin: 0 10px; color: #FF0000;">2</span>
                <span>已处置设备总量</span><span style="margin: 0 10px; color: #FF0000;">2</span>
                <span>本机构已处置设备总量</span><span style="margin: 0 10px; color: #FF0000;">2</span>
            </div> -->
            <div class="table-content">
                <el-table 
                    style="width: 100%"
                    height="100%"
                    :data="list"
                >
                    <el-table-column
                        label="工单状态"
                        prop="jobStatusLable"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        label="时间"
                        prop="jobTimes"
                        width="170"
                    >
                        <template slot-scope="{row}">
                            {{ row.jobTimes.jobCreateTime }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="期望接单时长"
                        prop="limitMinTime"
                        width="150"
                    >
                        <template slot-scope="{row}">
                            {{ row.jobStatus === 20 ? `${row.limitMinTime}分钟` : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="超时倒计时"
                        prop="timeoutDown"
                        width="200"
                    >
                        <template slot-scope="{row}">
                            <span v-if="row.jobStatus !== 20">-</span>
                            <span v-if="row.jobStatus === 20 && row.timeoutDown >= 0" style="color: green;">
                                {{ formatSecond(row.timeoutDown) }}
                            </span>
                            <span v-if="row.jobStatus === 20 && row.timeoutDown < 0" style="color: red;">
                                超时{{ formatSecond(-row.timeoutDown) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="故障类型"
                        prop="faultNames"
                        width="140"
                    >
                    </el-table-column>
                    <el-table-column
                        label="故障等级"
                        prop="faultLevel"
                        width="90"
                    >
                    </el-table-column>
                    <el-table-column
                        label="工单编号"
                        prop="jobCode"
                        width="210"
                    >
                    </el-table-column>
                    <el-table-column
                        label="电池类型"
                        prop="batteryType"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        label="电池ID"
                        prop="batteryCode"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        label="联系人"
                        prop="contacts"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        label="联系电话"
                        prop="contactNumber"
                        width="130"
                    >
                    </el-table-column>
                    <el-table-column
                        label="电池位置"
                        prop="position"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="140px"
                    >
                        <template slot-scope="{row}">
                            <a class="operate-a" @click="jumpDetailPage(row.jobCode)">查看</a>
                            <!-- <CreateFaultModal :fillData="row">
                                <a v-if="row.jobStatus === 10 || row.jobStatus === 20" class="operate-a">修改</a>
                            </CreateFaultModal> -->
                            <a v-if="row.jobStatus === 10 || row.jobStatus === 20" class="operate-a" @click="canceOrderHandel(row.jobCode)">取消</a>
                            <DistributeModal 
                                :faultLevel="Number(row.faultLevel)" 
                                :jobCode="row.jobCode" 
                                :getData="getTableData"
                            >
                                <a class="operate-a" v-if="row.jobStatus === 10">派发</a>
                            </DistributeModal>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-page">
                <div class="table-page-container">
                    <el-pagination
                        background
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableTool from './components/TableTool';
import DistributeModal from './components/DistributeModal';
import CreateFaultModal from './components/CreateFaultModal';
import './Index.less';
import axios from '@/utils/axios';
import moment from 'moment';
import { secondToDate } from '../../../utils/commonFun';

export default {
    name: 'FaultRescueManagement',
    components: {
        TableTool,
        DistributeModal,
        CreateFaultModal
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            list: [],
            total: 0,
            timer: null
        }
    },
    methods: {
        jumpDetailPage(jobCode) {
            this.$router.push({
                name: 'RescueDetail',
                params: {
                    jobCode
                }
            })
        },
        dataChangeHandel(rs) {
            const { records, total } = rs;
            this.list = records;
            this.total = total;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.$nextTick(() => {
                this.getTableData();
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$nextTick(() => {
                this.getTableData();
            });
        },
        getTableData() {
            this.$refs['tableTool'].getData();
        },
        canceOrderHandel(jobCode) {
            this.$confirm('确认取消该故障工单吗？取消会消除该次故障告警。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post('/api/rescueJob/cancelRescueJob', { jobCode }).then(() => {
                    this.$message.success('取消成功');
                    this.getTableData();
                }).catch(err => {
                    this.$message.error(err);
                });
            }).catch(() => {       
            });
        },
        createTimeoutTimer() {
            const listCopy = JSON.parse(JSON.stringify(this.list));
            listCopy.forEach(item => {
                if (item.jobStatus === 20) {
                    const { limitMinTime, jobTimes } = item;
                    const jobAssignTime = jobTimes.jobAssignTime;
                    const useSecond = moment().unix() - moment(jobAssignTime).unix()
                    const timeoutDown = limitMinTime * 60 - useSecond;
                    item.timeoutDown = timeoutDown;
                }
            });
            this.list = listCopy;
        },
        formatSecond(second) {
            return secondToDate(second);
        }
    },
    mounted() {
        this.timer = setInterval(this.createTimeoutTimer, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timer, this.createTimeoutTimer);
    }
}
</script>